import React, { lazy, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { AuthContext, AuthProvider } from "./components/AuthContext.js"; // Import AuthContext
import "./app.css"

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

// A wrapper component to protect routes

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div> {/* Styled spinner */}
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" /> // Redirect to login if not authenticated
        )
      }
    />
  );
};



function App() {
  return (
    <AuthProvider>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Protect the /app route */}
          <ProtectedRoute path="/app" component={Layout} />

          {/* Redirect root path to login */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;


// import React, { lazy } from "react";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Redirect,
// } from "react-router-dom";
// import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

// const Layout = lazy(() => import("./containers/Layout"));
// const Login = lazy(() => import("./pages/Login"));
// const CreateAccount = lazy(() => import("./pages/CreateAccount"));
// const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

// function App() {
//   return (
//     <>
//       <Router>
//         <AccessibleNavigationAnnouncer />
//         <Switch>
//           <Route path="/login" component={Login} />
//           <Route path="/create-account" component={CreateAccount} />
//           <Route path="/forgot-password" component={ForgotPassword} />

//           {/* Place new routes over this */}
//           <Route path="/app" component={Layout} />
//           {/* If you have an index page, you can remothis Redirect */}
//           <Redirect exact from="/" to="/login" />
//         </Switch>
//       </Router>
//     </>
//   );
// }

// export default App;
